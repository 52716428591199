
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Oswald', cursive;
}


body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'Oswald';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cart-holder{
  display: grid;
  grid-template:
  "a b"
  "c d";
}

.cart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border: dashed;
  place-self: center;
}

.cart-header{
  font-size: 1rem;
    padding: 1rem;
}

.cart-row{
  display: flex;
  width: 100%;

  place-content: center;
}

.cart-number{
  border: outset;

}

.ContainerButton{
  size: 1.2rem;
  display: flex;
  justify-content: right;
  text-align: center;
  margin-left: 350%;
}

.popButton{
  font-size: 22px;
  padding: 8px 8px;
  width: 100%;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 8px;
}

.buttonBuy{
  color: white;
  font-weight: bold;
  border: none;
  padding: 0;
  background-color: #206D07;
}
.BallsDrawed{
  display: inline-flex;
  margin-top: 20px;
  margin-right: 5px;
  margin-left: 10px;
  justify-content: center;
}

.BallsDrawed h2{
  color: green;
}

.Background{
  font-size:'40px';
  text-align: 'center';
  margin-top: '40%'; 
  background: #4EA3BA;
  margin: '100px 15px';
  border-radius:'5px';
}

.popupContainer{
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right,#484C86, #878BBE, #484C86  );
  justify-content: "center";
}

.popup{
  display: grid;
  height: 16rem;
  justify-self: center;
  background-color: #282A50;
  margin: 50% 15px;
  border: medium solid black;
  border-radius: 10px;
}

.popup img{
  justify-content: center;
  align-self: center;
}

.popup h1{
  color: white;
  text-align: center;
  margin: 0;
  font-size: 40px;
  -webkit-text-stroke: 0.8px black;
}

.Header{
  text-align: center;
  justify-content: center;
  background:  linear-gradient(to right,#484C86, #878BBE, #484C86  );
}

.navigation{
  background: linear-gradient(#12144D,#0E126F, #12144D);
}

.logo img{
  width: 20%;
  height: 50%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.SearchBox{
  position: relative;
  height: 40px;
}

.SearchBox input{ 
  width: 95%;
  height: 30px;
  border: 2px solid yellow;
  border-radius: 6px;
  padding: 0 30px 0 5px;
}

.SearchBox .search-Btn{
  position: absolute;
  width:  30px;
  height: 70%;
  right: 10px;
  color: #fff;
  background: yellow;
  transform: translateY(-100%);
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  border-radius: 5px;
}

.content{
  width: 100%;
  height: 50px;
}

.content h1{
  height: 40px;
  margin-top: 1.5%;
  text-align: center;
  background: linear-gradient(#12144D,#0E126F, #12144D);
  color: #FCFD05;
}