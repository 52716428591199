@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

@media (min-width: 1366px) {
  :root {
    font-size: 115%;
  }
}

@media (min-width: 1720px) {
  :root {
    font-size: 140%;
  }
}

@media (min-width: 2000px) {
  :root {
    font-size: 1.1vw;
  }
}

* {
  font-family: "Oswald", sans-serif;
  margin: 0;
  padding: 0;
  user-select: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.alert {
  font: 14px Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  border: 1px solid lightgray;
  box-shadow: 3px 3px 12px lightgray;
}

p {
  margin: 12px;
}
@media (min-width: 1028px) {
  p {
    margin: 0px;
  }
}

button{
  cursor: pointer;
}
button:hover{
  opacity: 0.9;
}

.alert .buttons {
  float: right;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Retirar setas do input number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
